/* eslint-disable */
import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  Banner,
  ContactUsForm,
} from 'components';

import { SOCIAL, CONTACT, BASE_URL } from 'config';
import SVGFacebook from '../../../public/svg/social/facebook.svg';

const SALESFORCE_ORG_ID = process.env.GATSBY_SALESFORCE_ORG_ID;
const SALESFORCE_SITE_KEY = process.env.GATSBY_SALESFORCE_SITE_KEY;
const SALESFORCE_SITE_NAME = process.env.GATSBY_SALESFORCE_SITE_NAME;

const ContactPage = () => (
  <Layout page="contact-us">

    <Helmet
      title="How to Contact the Haka Tours Team | Haka Tours"
      meta={[
        {
          name: 'description', content: `Have a question about your New Zealand tour?
          The Haka Tours Advisor team here during NZ business hours and will respoind to your query as soon as possible and within 24 hours.`,
        },
        { name: 'og:title', content: 'How to Contact the Haka Tours Team | Haka Tours' },
        {
          name: 'og:description', content: `Have a question about your New Zealand tour?
          The Haka Tours Advisor team here during NZ business hours and will respoind to your query as soon as possible and within 24 hours.`,
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/contact-us/` }]}
    >
      <script language="javascript" src="https://assets.calendly.com/assets/external/widget.js" />
      <script src="https://www.google.com/recaptcha/api.js"></script>
      <script
         dangerouslySetInnerHTML={{
           __html: `(function timestamp() { var response = document.getElementById("g-recaptcha-response"); if (response == null || response.value.trim() == "") {var elems = JSON.parse(document.getElementsByName("captcha_settings")[0].value);elems["ts"] = JSON.stringify(new Date().getTime());document.getElementsByName("captcha_settings")[0].value = JSON.stringify(elems); } } setInterval(timestamp, 500));`,
         }}
       />
      <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
    </Helmet>

    <Banner backgroundImage="/images/gday-banner.jpg">
      <h1 className="c-heading c-heading--h0">Contact Us</h1>
    </Banner>
    <section className="l-contact-us l-section">
      <div className="l-container">
        <div className="l-contact-us__grid">
          <div>
            <h2 className="l-contact-us__title c-heading c-heading--h2">Send Us a Message</h2>
            <p>
              We’re here most days (except NZ public holidays) to answer your questions and provide advice,
              and we’ll get back to you as soon as possible – and definitely within two business days. Be sure to read through our Frequently Asked <a href="/about-us/faqs/" target="_blank">Questions page</a>
              &nbsp;which may answer all your questions about your New Zealand tour.
            </p>
            <div>
              <ContactUsForm />
            </div>
          </div>
          <div>
            <h2 className="c-heading c-heading--h2">Get in contact</h2>
            <ul className="l-contact-us__details-list">
              <li>
                <h5>Call</h5>
                <a href={`tel:${CONTACT.PHONE}`}>{CONTACT.PHONE}</a>
              </li>
              <li>
                <h5>Email</h5>
                <a href={`mailto:${CONTACT.EMAIL}`}>{CONTACT.EMAIL}</a>
              </li>
              <li>
                <h5>Message us</h5>
                <a href={SOCIAL.FACEBOOK.URL} className="l-contact-us__details-list__fb-btn">
                  <span className="u-sr-only">Facebook</span>
                  <SVGFacebook />
                </a>
              </li>
              <li>
                <h5>Write us</h5>
                <address>{CONTACT.ADDRESS}</address>
              </li>
              <li>
                <h5>Live chat</h5>
                <button
                  type="button"
                  className="u-reset-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    // This interacts a global JS chat widget: https://www.jivochat.com/api/
                    // eslint-disable-next-line
                    if (jivo_api && typeof jivo_api.open === 'function') {
                      jivo_api.open(); // eslint-disable-line
                    }
                  }}
                >
                  Live chat with us 9-5 Mon-Fri
                </button>
              </li>
              <li>
                <h5>Call back</h5>
                <button
                  type="button"
                  className="u-reset-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    // This interacts a global JS chat widget: https://www.jivochat.com/api/
                    // eslint-disable-next-line
                    Calendly.initPopupWidget({url: 'https://calendly.com/hakatours'});
                    return false;
                  }}
                >
                  Book a phone call with us
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default ContactPage;
